import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import C  from '../../utils/constants';
import { authHeaderClient } from 'utils/helperFunctions';
// Layouts
const DefaultFrontLayout = React.lazy(() => import('../../layouts/DefaultFrontLayout'));


class ClientPrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: null,
        }

        this._Authenticated().then((data) => {
            if (data.code == 100) {
                this.setState({ isAuthenticated: data.data });
            } else {
                this.setState({ isAuthenticated: data.data });
            }
        }).catch((error) => {
            this.setState({isAuthenticated: false });
        });  
    }

    async _Authenticated() {
        return await new Promise((resolve, reject) => {
            const headers = authHeaderClient();
            axios.get(C.CLIENT_API_URL + 'client-auth-user/' + headers.Token,{ headers: headers }).then(response => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            });
        });
    }

    render() {
        if (this.state.isAuthenticated !== null && this.state.isAuthenticated !== undefined) {
            if (localStorage.getItem("companyAdminuser") !== null && localStorage.getItem("companyAdminuser") !== undefined && localStorage.getItem("companyAdminuser") !== '') {
                return <Redirect to={`${process.env.PUBLIC_URL}/select-company`} />
            } else if(!this.state.isAuthenticated) {
                localStorage.removeItem('clientuser');
                return <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            } else {
                return <DefaultFrontLayout {...this.props} />
            }
        } else {
            return <div className="page-loader">Loading...</div>;
        }
    }
}

export default ClientPrivateRoute;

