import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import C  from '../../utils/constants';
import { authHeader } from 'utils/helperFunctions';
const DefaultLayout = React.lazy(() => import('../../layouts/DefaultLayout'));


class AdminPrivateRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: null,
        }

        this._Authenticated().then((data) => {
            if (data.code == 100) {
                localStorage.setItem('adminuser', JSON.stringify(data.user_detail));
                this.setState({ isAuthenticated: data.data });
            } else {
                this.setState({ isAuthenticated: data.data });
            }
        }).catch((error) => {
            this.setState({ isAuthenticated: false });
        });  
    }

    async _Authenticated() {
        return await new Promise((resolve, reject) => {
            const headers = authHeader();
            axios.get(C.ADMIN_API_URL + 'admin-auth-user/' + headers.Token,{ headers: headers }).then(response => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            });
        });
    }

    render() {
        if (this.state.isAuthenticated !== null && this.state.isAuthenticated !== undefined) {
            if(!this.state.isAuthenticated) {
                localStorage.removeItem('adminuser');
                return <Redirect to='/admin/login' />
            } else {
                return <DefaultLayout {...this.props} />
            }
        } else {
            return <div className="page-loader">Loading...</div>;
        }
    }
}

export default AdminPrivateRoute;

