import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fetchIntercept from 'fetch-intercept';
import C from './utils/constants';
import theme from './theme';
import { history } from './utils/history';
import AdminPrivateRoute from './components/AdminPrivateRoute';
import ClientPrivateRoute from './components/ClientPrivateRoute';
import axios from 'axios';

//import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';
import { doc } from 'prettier';

/* Admin Related imports START */
// Layouts
// const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const ChangePassword = React.lazy(() => import('./views/ChangePassword'));
const Error404 = React.lazy(() => import('./views/Error404'));
const Error500 = React.lazy(() => import('./views/Error500'));
/* Admin Related imports END */

// Pages
const ClientLogin = React.lazy(() => import('./views/Front/Login'));
const ClientForgotPassword = React.lazy(() =>
  import('./views/Front/ForgotPassword')
);
const ClientResetPassword = React.lazy(() =>
  import('./views/Front/ResetPassword')
);
const ClientTermsConditions = React.lazy(() =>
  import('./views/Front/TermsConditions')
);
const ClientPrivatePolicy = React.lazy(() =>
  import('./views/Front/PrivatePolicy')
);
const ClientSelectUser = React.lazy(() => import('./views/Front/SelectUser'));
const SelectCompanyUser = React.lazy(() =>
  import('./views/Front/SelectCompanyUser')
);
const ClientChangePassword = React.lazy(() =>
  import('./views/Front/ChangePassword')
);

const ClientError404 = React.lazy(() => import('./views/Error404'));
/* Front Related imports END */

class App extends Component {
  constructor(props) {
    super(props);
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.message != undefined && 401 === error.response.status) {
          localStorage.removeItem('admin_user');
          if (error.response.data.type === 'admin') {
            localStorage.removeItem('adminuser');
            history.push(process.env.PUBLIC_URL + '/admin/login');
          } else {
            localStorage.removeItem('clientuser');
            history.push(process.env.PUBLIC_URL + '/login');
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    fetchIntercept.register({
      request: function (url, config) {
        return [url, config];
      },
      requestError: function (error) {
        return Promise.reject(error);
      },
      response: function (response) {
        if (response.status === 401) {
          localStorage.removeItem('admin_user');
          if (response.data.type === 'admin') {
            localStorage.removeItem('adminuser');
            history.push(process.env.PUBLIC_URL + '/admin/login');
          } else {
            localStorage.removeItem('clientuser');
            history.push(process.env.PUBLIC_URL + '/login');
          }
        } else if (response.status === 101) {
          if (response.data.type === 'admin') {
            history.push(process.env.PUBLIC_URL + '/admin/dashboard');
          } else {
            history.push(process.env.PUBLIC_URL + '/dashboard');
          }
        } else {
          return response;
        }
      },
      responseError: function (error) {
        return Promise.reject(error);
      }
    });
  }

  loading = () => (
    <div id="loading">
      <div className="loading-center">
        <div className="outer"></div>
        <div className="inner"></div>
      </div>
    </div>
  );
  componentDidMount() {
    const timeoutDuration = 60 * 60 * 1000;
    const interval = setInterval(() => {
      var button = document?.getElementById('hello');
      if (button !== null) {
        clearInterval(interval);
        button?.addEventListener('click', () => {
          clearInterval(reloadTimeout);
          console.log('interval cleared');
          var reloadTimeout = setInterval(() => {
            console.log('realoding page');
            window.location.reload(true);
          }, timeoutDuration);
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.reloadTimeout);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history} basename={process.env.PUBLIC_URL}>
            <Suspense fallback={this.loading()}>
              <Switch>
                {/* Admin Routes START */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/login`}
                  render={(props) => {
                    return (
                      <Login
                        {...props}
                        name="Login"
                        title={`Login | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/forgot-password`}
                  render={(props) => {
                    return (
                      <ForgotPassword
                        {...ForgotPassword}
                        name="ForgotPassword"
                        title={`ForgotPassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/reset-password/:code`}
                  render={(props) => {
                    return (
                      <ResetPassword
                        {...props}
                        name="ResetPassword"
                        title={`ResetPassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/change-password/:code`}
                  render={(props) => {
                    return (
                      <ChangePassword
                        {...props}
                        name="ChangePassword"
                        title={`ChangePassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/404`}
                  render={(props) => {
                    return <Error404 {...props} name="Page Not Found" />;
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/500`}
                  render={(props) => {
                    return <Error500 {...props} name="Internal Server Error" />;
                  }}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/admin`}
                  render={(props) => <AdminPrivateRoute {...props} />}
                />

                {/* Admin Routes END */}

                {/* Client Routes START */}

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/login`}
                  render={(props) => {
                    return (
                      <ClientLogin
                        {...props}
                        name="Login"
                        title={`Login | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/forgot-password`}
                  render={(props) => {
                    return (
                      <ClientForgotPassword
                        {...ClientForgotPassword}
                        name="ForgotPassword"
                        title={`ForgotPassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/change-password/:code`}
                  render={(props) => {
                    return (
                      <ClientChangePassword
                        {...props}
                        name="ChangePassword"
                        title={`ChangePassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/reset-password/:code`}
                  render={(props) => {
                    return (
                      <ClientResetPassword
                        {...props}
                        name="ResetPassword"
                        title={`ResetPassword | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/terms-conditions`}
                  render={(props) => {
                    return (
                      <ClientTermsConditions
                        {...props}
                        name="Terms & Conditions"
                        title={`Terms & Conditions | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/privacy-policy`}
                  render={(props) => {
                    return (
                      <ClientPrivatePolicy
                        {...props}
                        name="Privacy-Policy"
                        title={`Privacy-Policy | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/select-company`}
                  render={(props) => {
                    return (
                      <ClientSelectUser
                        {...props}
                        name="Select Company"
                        title={`Select Company | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/select-company-user/:id`}
                  render={(props) => {
                    return (
                      <SelectCompanyUser
                        {...props}
                        name="Select Company User"
                        title={`Select Company User | ${C.SITE_NAME}`}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/404`}
                  render={(props) => {
                    return <ClientError404 {...props} name="Page Not Found" />;
                  }}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/`}
                  render={(props) => <ClientPrivateRoute {...props} />}
                />

                {/* Client Routes END */}
              </Switch>
            </Suspense>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default App;
