export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('adminuser'));

    if (user !== undefined && user !== null && user !== '' && user.api_token !== undefined && user.api_token !== null && user.api_token !== '') {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.api_token,
            'Token': user.api_token
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }
}
export function authHeaderClient() {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem('clientuser'));

  if (user !== undefined && user !== null && user !== '' && user.api_token !== undefined && user.api_token !== null && user.api_token !== '') {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.api_token,
          'Token': user.api_token
      };
  } else {
        let user = JSON.parse(localStorage.getItem('companyAdminuser'));
        if (user && user.api_token) {
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.api_token,
                'Access-Control-Allow-Origin': '*',
                
            };
        } else {
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            };
        }
  }
}

export function isAuthenticated() {
    let user = JSON.parse(localStorage.getItem('adminuser'));
    if (user !== undefined && user !== null && user !== '' && user.api_token !== undefined && user.api_token !== null && user.api_token !== '') {
        return true;
    } else {
        return false
    }
}

export function getAdminData() {
    let user = JSON.parse(localStorage.getItem('adminuser'));
    if (user !== undefined && user !== null && user !== '' && user.api_token !== undefined && user.api_token !== null && user.api_token !== '') {
        return user;
    } else {
        return {};
    }
}
export function getClientData() {
  let user = JSON.parse(localStorage.getItem('clientuser'));
  if (user !== undefined && user !== null && user !== '' && user.api_token !== undefined && user.api_token !== null && user.api_token !== '') {
      return user;
  } else {
      return {};
  }
}

export function formatNumber  (value, pattern, stDev_decimal, cc = 'en-US', currency = 'USD') {
    if (value === '' || value === null) return null;
    if (isNaN(value)) return null;

    var options = {};
    var decimalPlaces = 0;
    var invalidPattern = false;
    var finalNumber = value;

    if (pattern !== undefined) {
      if (
        (pattern?.toString().match(/\./g) || []).length > 1 ||
        (pattern?.toString().match(/\$/g) || []).length > 1 ||
        (pattern?.toString().match(/\%/g) || []).length > 1 ||
        ((pattern?.toString().match(/\$/g) || []).length > 0 &&
          (pattern?.toString().match(/\%/g) || []).length > 0)
      )
        invalidPattern = true;

      if (invalidPattern) return value;

      if ((pattern?.toString().match(/\./g) || []).length > 0)
        decimalPlaces = pattern.split('.')[1].length;

      if (pattern?.endsWith('%')) {
        options.style = 'percent';
        if ((pattern.toString().match(/\./g) || []).length > 0)
          decimalPlaces -= 1;
        value = value / 100;
      }

      // else if (pattern.startsWith('$')) {
      //   options.style = 'currency';
      //   options.currency = currency;
      // }

      options.minimumFractionDigits = decimalPlaces
        ? decimalPlaces
        : stDev_decimal
        ? 2
        : 0;
      options.maximumFractionDigits = decimalPlaces
        ? decimalPlaces
        : stDev_decimal
        ? 2
        : 0;

      finalNumber = new Intl.NumberFormat(cc, options).format(value);

      if ((pattern?.toString().match(/\,/g) || []).length == 0)
        finalNumber = finalNumber.toString().replace(/\,/g, '');

      return finalNumber;
    }
   
}

// Function to add headers in Summary table
export function createHeaderRow(yearData) {
  return [
    '',
    yearData.prevTwoYear,
    yearData.prevOneYear,
    yearData.currentYear,
    'Average',
    'StDev',
    'StDev%',
    `${yearData.prevTwoYear} - ${yearData.prevTwoYear - 1}`,
    `${yearData.prevOneYear}-${yearData.prevTwoYear}`,
    `${yearData.currentYear}-${yearData.prevOneYear}`
  ].map((cell) => ({
    text: cell,
    bold: true,
    margin: [0, 0, 0, 0],
    border: [false, false, false, false],
    fontSize: 8
  }));
}

export function createMonthlyStatisticsRow(yearData) {
  return [
    {
      text: '',
      margin: [1, 1, 1, 1],
      border: [false, false, false, false]
    },
    {
      text: `Monthly Statistics (${yearData.prevTwoYear}-${yearData.currentYear})`,
      margin: [1, 1, 1, 1],
      border: [false, false, false, false],
      fontSize: 8,
      alignment: 'center'
    },
    {
      text: 'Change',
      margin: [1, 1, 1, 1],
      border: [false, false, false, false],
      fontSize: 8,
      alignment: 'center'
    }
  ];
}

// Function to add rows in Summary Table
export function createTableRow(item, yearData, valueFormat) {
  return [
    'CompanyName',
    yearData.prevTwoYear,
    yearData.prevOneYear,
    yearData.currentYear,
    'Average',
    'StDev',
    'StDev_percent',
    `${yearData.prevTwoYear}_${yearData.prevTwoYear - 1}`,
    `${yearData.prevOneYear}_${yearData.prevTwoYear}`,
    `${yearData.currentYear}_${yearData.prevOneYear}`
  ].map((cell) => {
    if (cell === 'CompanyName') {
      return {
        text: item[`CompanyName`],
        margin: [0, 0, 0, 0],
        border: [false, false, false, false],
        fontSize: 7
      };
    }
    return {
      text:
        cell === 'StDev_percent' && valueFormat?.indexOf('%') === -1
          ? formatNumber(item[cell], valueFormat, true) + '%'
          : formatNumber(item[cell], valueFormat, false),
      margin: [0, 0, 0, 0],
      border: [false, false, false, false],
      fontSize: 7
    };
  });
}